import React, { useEffect, useState } from 'react'
import { categoryInitialValue, categoryValidationSchema, defaultCategoryValidationSchema } from '../../../../common/Validation';
import { useFormik } from 'formik';
import { capitalizeWords, convertByteToMB } from '../../../../../utils/HelperFuncitons';
import toast from 'react-hot-toast';
import { addOrUpdateDefaultCategoryApi, addVehicleCategoryApi, cityByCountryApi, countryListApi, defaultCategoryByIdApi, getCategoryByIdApi, updateVehicleCategoryApi, uploadFileApi } from '../../../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../../../common/Spinwheel';
import { S3_URL } from '../../../../../utils/BaseUrl';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import SubscriptionTable from '../SubscriptionTable';
import SurchargePriodsTable from '../SurchargePriodsTable';
import BonusTable from '../BonusTable';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const FormField = (({ fields }, index) => (
  <div key={index} className="mb-1">
    <label htmlFor={fields.id} className="form-label">{fields.label}</label>
    {fields?.type=="select" ? <select 
          className="form-control"
          id={fields.id}
          name={fields.name}
          value={fields.value}
          onChange={fields.onChange}
          onBlur={fields.onBlur}
          disabled={fields?.disabled}
          placeholder='Enter'
    >
      {fields?.options?.map(ele=><option value={ele}>{ele}</option>)}
    </select> :
    <input
      type={fields.type || "text"}
      className="form-control"
      id={fields.id}
      name={fields.name}
      value={fields.value}
      onChange={fields.onChange}
      onBlur={fields.onBlur}
      disabled={fields?.disabled}
      placeholder='Enter'
    />}
    {fields.touched && fields.errors ? (<div className='req-error'>{fields.errors}</div>) : null}
  </div>
));

function AddOrEditCategory() {
  const [addLoader, setAddLoader] = useState(false);
  const [cityOptions, setCityOptions] = useState([])
  const location = useLocation();
  const navigate = useNavigate();
  const categoryType = location.pathname?.split("/")?.slice?.(-1)?.[0]?.split?.("-")?.[0];
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));
  const [loader, setLoader] = useState(id ? true : false)
  const [subscriptionLength, setSubscriptionsLength] = useState(0)

  // console.log(location)
  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      const res = categoryType === "category" ? await updateVehicleCategoryApi(values) : await addOrUpdateDefaultCategoryApi(values)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))

        if (!Boolean(id)) {
          setId(res.data?.id);
          navigate(location.pathname + `?id=${res.data?.id}`)
        }
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const formik = useFormik({
    initialValues: categoryInitialValue,
    onSubmit: handleSubmit,
    validationSchema: categoryType === "category" ? categoryValidationSchema : defaultCategoryValidationSchema
  })

  console.log(formik)

  const [key, setKey] = useState(0)

  const FILE_ACCEPT = ["image/jpg", "image/jpeg", "image/png"]

  const fileUploadHandler = async (e) => {
    try {

      const file = e.target.files[0];
      if (file) {

        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error("Invalid File Format")
          return;
        }

        const fileSizeInMB = convertByteToMB(file.size || 0);

        if (fileSizeInMB > 5) {
          return toast.error("File Size Exceeds. Max Image Should be of 5 MB")
        }

        formik.setFieldTouched('image', true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "driver")

        const res = await uploadFileApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          formik.setFieldValue('image', res.data?.data)

        }
      }

    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }
  }

  const handleRemoveImage = () => {
    setKey(key => key + 1)
    formik.setFieldValue("image", "")
  }

  const filterCites = async () => {
    try {
      const res = await cityByCountryApi("", formik?.values?.countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue, cb) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        cb(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const countryOnChange = debounce(filterCountry, 1000);

  const countryOptions = (inputValue, callback) => {
    countryOnChange(inputValue, callback);
  }

  const handleCityChange = (city) => {

    formik.setFieldValue('cityId', city.value);
    formik.setFieldValue('cityName', city.label);
  }

  const handleCountryChange = (country) => {
    if(formik.values.countryId != country.value){
      setCityOptions([])
      formik.setFieldValue('countryId', country.value);
      formik.setFieldValue('countryName', country.label);
      formik.setFieldValue('cityName', "");
      formik.setFieldValue('cityId', "");
    }
  }

  useEffect(() => {
    if (formik?.values?.countryId) {
      filterCites()
    }

  }, [formik?.values?.countryId])

  const formFields = [
    { label: "Class name ", id: "category", name: "category", disabled: categoryType === "category", type: "text", value: formik?.values?.category, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.category, errors: formik?.errors?.category },
    { label: "Base rate", id: "baseRate", name: "baseRate", type: "text", value: formik?.values?.baseRate, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.baseRate, errors: formik?.errors?.baseRate },
    { label: "Per Km", id: "perMiles", name: "perMiles", type: "text", value: formik?.values?.perMiles, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.perMiles, errors: formik?.errors?.perMiles },
    { label: "Per minute", id: "perMinute", name: "perMinute", type: "text", value: formik?.values?.perMinute, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.perMinute, errors: formik?.errors?.perMinute },
    // { label: "Surge", id: "surcharge", name: "surcharge", type: "text", value: formik?.values?.surcharge, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.surcharge, errors: formik?.errors?.surcharge },
    { label: "Ride booking fee", id: "rideBookingFee", name: "rideBookingFee", type: "text", value: formik?.values?.rideBookingFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.rideBookingFee, errors: formik?.errors?.rideBookingFee },
    { label: "Wait fee", id: "waitFee", name: "waitFee", type: "text", value: formik?.values?.waitFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.waitFee, errors: formik?.errors?.waitFee },
    { label: "Cancellation fee", id: "cancellationFee", name: "cancellationFee", type: "text", value: formik?.values?.cancellationFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.cancellationFee, errors: formik?.errors?.cancellationFee },
    { label: "Platform fee (%)", id: "platformFee", name: "platformFee", type: "text", value: formik?.values?.platformFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.platformFee, errors: formik?.errors?.platformFee },
    { label: "Other Stop Fee", id: "otherStopFee", name: "otherStopFee", type: "text", value: formik?.values?.otherStopFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.otherStopFee, errors: formik?.errors?.otherStopFee },
    { label: "City Tax(%)/ Levy Fee", id: "cityTax", name: "cityTax", type: "text", value: formik?.values?.cityTax, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.cityTax, errors: formik?.errors?.cityTax },
    { label: "Tax type", id: "taxType", name: "taxType", type: "select", value: formik?.values?.taxType, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.taxType, errors: formik?.errors?.taxType,options:["City Tax","Levy Fee"] },
    // { label: "Promotional Discount (%)", id: "promotionalDiscountFee", name: "promotionalDiscountFee", type: "text", value: formik?.values?.promotionalDiscountFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.promotionalDiscountFee, errors: formik?.errors?.promotionalDiscountFee },
    { label: "Subscription Fee (%)", id: "subscriptionFee", name: "subscriptionFee", type: "text", value: formik?.values?.subscriptionFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.subscriptionFee, errors: formik?.errors?.subscriptionFee },
    { label: "Commission Fee (%)", id: "commissionFee", name: "commissionFee", type: "text", value: formik?.values?.commissionFee, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.commissionFee, errors: formik?.errors?.commissionFee },
    { label: "Weather (%)", id: "weather", name: "weather", type: "text", value: formik?.values?.weather, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.weather, errors: formik?.errors?.weather },
    { label: "Minimum fare", id: "minimumFare", name: "minimumFare", type: "text", value: formik?.values?.minimumFare, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.minimumFare, errors: formik?.errors?.minimumFare },
    { label: "Min wallet balance", id: "minDriverWalletBalance", name: "minDriverWalletBalance", type: "text", value: formik?.values?.minDriverWalletBalance, onChange: formik?.handleChange, onBlur: formik?.handleBlur, touched: formik?.touched?.minDriverWalletBalance, errors: formik?.errors?.minDriverWalletBalance },
  ];

  const getCategoryOrDefaultcategory = async (id) => {
    try {
      setLoader(true)
      const res = categoryType === "defaultCategory" ? await defaultCategoryByIdApi(id) : await getCategoryByIdApi(id);
      if (res.status === 200) {
        formik.setValues((res?.data?.data))
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (id) {
      getCategoryOrDefaultcategory(id)
    }
  }, [])

  // console.log(formik.values)

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}

              <div className="card ">
                <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
                  <h1>Rides charge</h1>
                </div>
                {
                  loader ?
                    <div className="text-center"> <Spinwheel /></div> : <>
                      <div className="card-body">
                        <div className="p-3">

                          <div className="row">
                            <form onSubmit={formik?.handleSubmit} >

                              <div className='row'>
                                <div className='col-md-9'>
                                  {categoryType === "category" && <div className="row">
                                    <div className="col-md-6 mb-2">
                                      <label htmlFor="country" className="form-label">Country</label>
                                      {/* {console.log(formik?.values?.country, "kamaal ki country")} */}
                                      <AsyncSelect cacheOptions loadOptions={countryOptions} defaultOptions value={{ label: formik?.values?.countryName, value: formik?.values?.countryId }} onChange={handleCountryChange} onBlur={formik?.handleBlur} />
                                      {formik?.touched?.country && formik?.errors?.country ? (<div className='req-error'>{formik?.errors?.country}</div>) : null}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                      <label htmlFor="city" className="form-label">City</label>
                                      <ReactSelect options={cityOptions} isDisabled={!formik.values?.countryId} defaultOptions value={{ label: formik?.values?.cityName, value: formik?.values?.cityId }} onChange={handleCityChange} onBlur={formik?.handleBlur} />
                                      {formik?.touched?.city && formik?.errors?.city ? (<div className='req-error'>{formik?.errors?.city}</div>) : null}
                                    </div>
                                  </div>}
                                  <div className="row row-cols-3">
                                    {formFields.map((field, index) => (
                                      <FormField key={index} fields={field} />
                                    ))}
                                  </div>

                                  <div className="mb-3">
                                    <div className=' d-flex align-items-center justify-content-between'>
                                      <div className='p-2'>
                                        <div className="form-check form-switch ">
                                          <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.isFlash} id="flexSwitchCheckDefault" onChange={() => formik?.setFieldValue("isFlash", !Boolean(formik?.values?.isFlash))} />
                                          <label htmlFor="cityTax" className="form-label">Flash</label>
                                        </div>
                                      </div>
                                      <div className='p-2'>
                                        <div className="form-check form-switch ">
                                          <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.isSubscription} id="flexSwitchCheckDefault" onChange={(e) => (!subscriptionLength && e.target.checked) ? toast.error("Please Add Subscription Plan First!!") : formik?.setFieldValue("isSubscription", !Boolean(formik?.values?.isSubscription))}  />
                                          <label htmlFor="subscription" className="form-label">Subscription</label>
                                        </div>
                                      </div>
                                      <div className='p-2'>
                                        <div className="form-check form-switch ">
                                          <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.isCommission} id="flexSwitchCheckDefault" onChange={() => formik?.setFieldValue("isCommission", !Boolean(formik?.values?.isCommission))} />
                                          <label htmlFor="commission" className="form-label">Commission</label>
                                        </div>
                                      </div>
                                      <div className='p-2'>
                                        <div className="form-check form-switch ">
                                          <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.isWeather} id="isWeather" onChange={() => formik?.setFieldValue("isWeather", !Boolean(formik?.values?.isWeather))} />
                                          <label htmlFor="isWeather" className="form-label">Weather</label>
                                        </div>
                                      </div>
                                    </div>
                                    {formik?.errors?.isCommission || formik.isSubscription ? (<div className='req-error'>{formik?.errors?.isCommission || formik.isSubscription}</div>) : null}
                                  </div>
                                </div>
                                <div className="d-flex col-md-3" >
                                  <div className="d-flex position-relative justify-content-center align-items-center p-4 w-100">
                                    <div className='border border-2 rounded  p-0 d-flex flex-column align-items-center' style={{ width: "100%", height: '80%' }} >
                                      <label htmlFor="truckFrontSide" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                        <input key={key} type="file" disabled={categoryType === "category"} className='btn btn_upload_file d-none ' name="truckFrontSide" id='truckFrontSide' onChange={(e) => fileUploadHandler(e)} accept={FILE_ACCEPT} />
                                        {formik?.values?.image ?
                                          <img src={S3_URL + "/" + formik.values.image} alt="" className='img-fluid' />
                                          : <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                          </svg>}
                                      </label>
                                    </div>
                                    {formik?.values?.image && categoryType === "defaultCategory" && <span className='position-absolute text-danger' style={{ top: '54px', right: '17px', cursor: "pointer" }} onClick={handleRemoveImage}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                      </svg>
                                    </span>}
                                  </div>
                                </div>
                              </div>
                              <div className="text-end">
                                <button className="btn btn-primary" type="submit" disabled={addLoader}>
                                  {addLoader ? <Spinwheel /> : (formik?.values?.id ? "Update" : "Add")}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> </>}
              </div>
              {Boolean(id) && <>
                <SurchargePriodsTable categoryType={categoryType} categoryId={id} />
                {/* <BonusTable categoryType={categoryType} categoryId={id} /> */}
                <SubscriptionTable categoryType={categoryType} categoryId={id} setSubscriptionsLength={setSubscriptionsLength}/>
              </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddOrEditCategory