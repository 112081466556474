import React, { useEffect, useState } from 'react'
import CommonTable from '../../common/Table/CommonTable'
import { useFormik } from 'formik';
import { addOrUpdateBonus, bonusCityAndCountryListApi, bonusListApi, cityByCountryApi, countryListApi, enableOrDisableBonusApi, getAdminConfigurationApi, updateAdminConfigurationsApi, updateBonusStatus } from '../../../utils/ApiEndpointFunctions';
import ReactSelect from 'react-select';
import MinimumRideChargesForBonusModal from '../../common/Modal/MinimumRideChargesForBonusModal';
import { bonusInitialValue, bonusValidationSchema, minimumChargesBonusInitialValue, minimumChargesBonusInitialValueSchema } from '../../common/Validation';
import { capitalizeWords, convertTimeTo12HrFormat } from '../../../utils/HelperFuncitons';
import toast from 'react-hot-toast';
import AddBonusFormModal from '../../common/Modal/AddBonusFormModal';
import DeletedModal from '../../common/Modal/DeletedModal';

function Bonus() {
  const [minChargesLoader, setMinChargesLoader] = useState(false);
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const InputWithButtonModalId = "InputWithButtonModalId"
  const BonusModalId = "BonusModalId";
  const [tableData, setTableData] = useState([]);
  const [bonusCitytableData, setBonusCitytableDat] = useState([]);
  const [loader, setLoader] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false)
  const [addLoader, setAddLoader] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [bonusTableLoader, setBonusTableLoader] = useState(false);
  

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getBonusList = async (countryId, cityId) => {
    try {
      setBonusTableLoader(true)
      const res = await bonusListApi(countryId, cityId)
      if (res.status === 200) {
        setTableData(res.data?.BonusList)
      }
    } catch (error) {
      console.log(error)
    }
    setBonusTableLoader(false)
  }

  const selectBonusHandler = (ele) => {
    getBonusList(ele.countryId, ele.cityId)
  }

  const getBonusCityAndCountry = async () => {
    try {
      setLoader(true)
      const res = await bonusCityAndCountryListApi(searchFormik.values.countryId?.value, searchFormik.values.cityId?.value)
      if (res.status === 200) {
        // console.log(res.data?.data)
        setBonusCitytableDat(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const deleteModalId = "bonusDeleteModal"
  const deleteBonus = async () => {
    setAddLoader(true)
    try {
      const res = await updateBonusStatus({ id: selectedId.id, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getBonusList(selectedId.countryId, selectedId.cityId);
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const updatingValueHandler = (value) => {
    if (value?.duration) {
      value.duration = {
        label: capitalizeWords(value?.duration),
        value: value?.duration
      };
    }
    if (!value?.from || !value?.to) {
      value.from = ""
      value.to = ""
    }
    const updateObj = { ...value, cityId: { label: value.cityName, value: value.cityId }, countryId: { label: value.countryName, value: value.countryId } }
    formik.setValues(updateObj);
  };

  const handleEnableOrDisableBonus = async (value, index) => {
    try {
      setStatusLoading(true)
      const res = await enableOrDisableBonusApi({ id: value.id, status: !Boolean(tableData[index].isEnabled) })
      if (res.status === 200) {
        toast.success(res.data.message)
        tableData[index].isEnabled = !Boolean(tableData[index].isEnabled)
        setTableData(tableData)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
    setStatusLoading(false)
  }
  

  const submitMinRideCharges = async (value) => {
    try {
      setMinChargesLoader(true)
  
      const res = await updateAdminConfigurationsApi(value)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        document.getElementById(InputWithButtonModalId).click();
        getBonusCityAndCountry();
      }
    } catch (error) {
      console.log(error);
    }
    setMinChargesLoader(false)
  }

  const minimumChargesFormik = useFormik({
    initialValues: minimumChargesBonusInitialValue,
    onSubmit: submitMinRideCharges,
    validationSchema: minimumChargesBonusInitialValueSchema
  })

  console.log(minimumChargesFormik.values)
  const handleSubmit = async (values) => {
    try {
      let payload = JSON.parse(JSON.stringify(values));
      setAddLoader(true)
      payload['duration'] = payload.duration.value
      payload.countryId = payload?.countryId?.value;
      payload.cityId = payload?.cityId?.value;

      console.log(payload);

      const res = await addOrUpdateBonus(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getBonusList(payload.countryId, payload.cityId);
        document.getElementById(BonusModalId).click();
        formik.resetForm();
      }

    } catch (error) {
      console.log(error);
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const formik = useFormik({
    initialValues: bonusInitialValue,
    onSubmit: handleSubmit,
    validationSchema: bonusValidationSchema
  })


  // const getMinCharges = async () => {
  //   try {
  //     const res = await getAdminConfigurationApi()
  //     if (res.status === 200) {
  //       minimumChargesFormik.setFieldValue("amount", res.data?.data?.minRideChargesForBonus)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }

  const searchFormik = useFormik({
    initialValues: {
      countryId: null,
      cityId: null,
    },
  })

  useEffect(() => {
    filterCountry();
    // getMinCharges()
  }, [])

  useEffect(() => {
    getBonusCityAndCountry();
  }, [searchFormik.values])
 

  const bonusHeaderData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-2", key: "name" },
    { name: "No Of Trips", className: "col-1", key: "noOfTrips" },
    { name: "Duration", className: "col-1", key: "duration" },
    { name: "From", className: "col-1", key: "from" },
    { name: "To", className: "col-1", key: "to" },
    { name: "Minimum Rating", className: "col-1", key: "minRating" },
    { name: "Amount", className: "col-1", key: "amount" },
    // { name: "Min ride charge", className: "col-1", key: "minRideCharge" },
    { name: "Enable", className: "col-1", key: "isEnabled" },
    { name: "", className: "col-1", key: "action" }
  ];

  const bonusTableRowData = tableData.map((ele, index) => ({
    index: index + 1,
    name: ele?.name || "-",
    noOfTrips: ele?.noOfTrips || "-",
    duration: ele?.duration && typeof ele.duration === "object" ? (ele?.duration?.label) : ele?.duration ? capitalizeWords(ele.duration) : "",
    from: ele?.from ? convertTimeTo12HrFormat(ele?.from) : "-",
    to: ele?.to ? convertTimeTo12HrFormat(ele?.to) : "-",
    minRating: ele?.minimumRating ?? "-",
    amount: ele?.amount ?? "-",
    // minRideCharge: ele?.minRideChargesForBonus ?? "-",
    isEnabled: (
      <div className="form-check form-switch ">
        <input className="form-check-input" type="checkbox" checked={ele?.isEnabled} disabled={statusLoading} role="switch" id="flexSwitchCheckDefault" onChange={() => handleEnableOrDisableBonus(ele, index)} />
      </div>
    ),
    action: (<div className="dropdown">
      <button
        className=" border-0 bg-white"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-three-dots-vertical"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
        </svg>
      </button>
      <ul
        className="dropdown-menu"
        style={{ zIndex: "1000" }}
      >
        <li className="dropdown-item">
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${BonusModalId}`}
            onClick={() => { updatingValueHandler(ele) }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </div>
        </li>

        <li className="dropdown-item" >
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${deleteModalId}`}
            onClick={() => { setSelectedId({ id: ele?.id, countryId: ele.countryId, cityId: ele.cityId }) }}
            style={{ cursor: "pointer" }}
          >
            Delete
          </div>
        </li>
      </ul>
    </div>)
  }))

  const bonusCountryHeaderData = [
    { name: "S.No.", className: "col", key: "index" },
    { name: "Country", className: "col", key: "countryName" },
    { name: "City", className: "col", key: "cityName" },
    { name: "Bonus count", className: "col", key: "bonusCount" },
    { name: "Min ride charge", className: "col", key: "minRideChargesForBonus" },
  ]

  const bonusCountryRowData = bonusCitytableData.map((ele, index) => {
    return {
      index: index + 1,
      countryName: <div style={{ cursor: "pointer" }} className='text-primary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCites" onClick={() => { selectBonusHandler(ele) }}>{ele?.countryName}</div>,
      cityName: ele?.cityName || "-",
      bonusCount: ele?.bonusCount || "-",
      minRideChargesForBonus: <div style={{ cursor: "pointer" }} className='text-primary' data-bs-toggle="modal" data-bs-target={`#${InputWithButtonModalId}`} onClick={() => { selectBonusHandler(ele); minimumChargesFormik.setValues({ minRideChargesForBonus: ele?.minRideChargesForBonus, cityId: ele?.cityId }) }}>{ele?.minRideChargesForBonus || "-"}</div>, 
    }
  })

  return (
    <>

      <div className='card'>
        <div className="card-header">
          <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
            <h1>Bonus</h1>
            <div style={{ display: "-webkit-inline-box" }}>              
                <div className="d-flex gap-2 justify-content-end align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <label htmlFor="city" className="form-label">Country</label>
                      <ReactSelect className='reactSelectMinWidth' options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                      {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <label htmlFor="city" className="form-label">City</label>
                      <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                      {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
                    </div>
                  <div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => { searchFormik.resetForm(); }}
                        disabled={!searchFormik?.values?.countryId}
                      >
                        Reset filter
                      </button>
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#${InputWithButtonModalId}`}
                  >
                    Minimum Ride Charges
                  </button> */}
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#${BonusModalId}`}
                    onClick={formik.resetForm}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
        <div className="card-body">
          <CommonTable headers={bonusCountryHeaderData} data={bonusCountryRowData} loading={loader} />
        </div>
      </div>

      <div className="offcanvas offcanvas-end" style={{ width: "50%" }} tabIndex="-1" id="offcanvasRightCites" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h1 id="offcanvasRightLabel">Bonus</h1>
          <button type="button" className="btn-close text-reset" id={"offcanvasCity"} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="card-body">
            <CommonTable headers={bonusHeaderData} data={bonusTableRowData} loading={bonusTableLoader} />
          </div>
        </div>
      </div>
     
      <MinimumRideChargesForBonusModal
        modalId={InputWithButtonModalId}
        formik={minimumChargesFormik}
        loader={minChargesLoader}
      />

      <AddBonusFormModal modalId={BonusModalId} addLoader={addLoader} formik={formik} countryOptions={countryOptions} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this Bonus"}
        deletedHandler={deleteBonus}
        loader={addLoader}
      />
      {/* <CountryAndCityModal type={"City"} modalId={countryOrCiteModal} formik={formik} loader={addCityModal} selectedCountry={selectCountry} />
      <DeletedModal
        modalId={deleteModalId}
        message={"This city"}
        deletedHandler={removeCityHandler}
        loader={addCityModal}
      /> */}
    </>)
}

export default Bonus