import React from 'react'
import Spinwheel from '../Spinwheel'

function MinimumRideChargesForBonusModal(props) {
    const {formik, loader, modalId } = props

    return (
        <>
        
            <div
                className="modal fade"
                id={modalId}
                tabIndex="-1"
                aria-labelledby="deleteModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button
                                id="confirmModalCloseBtn"
                                type="button"
                                className="btn-close block-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body pe-0 ps-0">
                            <div className="container">
                                <h2>Add Minimum Charges of a Ride</h2>
                                <form onSubmit={formik?.handleSubmit} >

                                    <div className="mb-3">
                                        <label htmlFor="minRideChargesForBonus" className="form-label">Amount</label>
                                        <input type="number" className="form-control" id="minRideChargesForBonus" placeholder="Enter amount" name='minRideChargesForBonus' value={formik?.values?.minRideChargesForBonus} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()} />
                                        {formik?.touched?.minRideChargesForBonus && formik?.errors?.minRideChargesForBonus ? (<div className='req-error'>{formik?.errors?.minRideChargesForBonus}</div>) : null}
                                    </div>
                                    <button type="submit" disabled={loader} className="btn btn-primary">
                                        {loader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MinimumRideChargesForBonusModal