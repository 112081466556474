import { useFormik } from 'formik'
import React, { useState } from 'react'
import { driverRateIntialValue, driverRateValidationSchema } from '../../../common/Validation';
import { updateDriverMinWalletBalanceApi } from '../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { useAdminContext } from '../../../../context/adminContext';

function DriverRate() {

  const [submitLoader, setSubmitLoader] = useState(false);
  const { adminDetail, setAdminDetail } = useAdminContext()

  const rateFormik = useFormik({
    initialValues: { ...driverRateIntialValue, minWidhdrawlAmount: adminDetail?.adminConfigurationModel?.minWidhdrawlAmount },
    validationSchema: driverRateValidationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitLoader(true)
        const res = await updateDriverMinWalletBalanceApi(values)
        if(res.status === 200) {
          console.log(res.data?.data)
          setAdminDetail({ ...adminDetail, adminConfigurationModel: res.data?.data })
          toast.success(res.data.message);
        }
      } catch (error) {
        console.error(error)
      } 
      
        setSubmitLoader(false)
      }


    
  })


  return (
    <div>
      
      <div className="">
        <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
          Rate
        </div>
        <div className="card-body" >
          <div className='p-5'>
            <form onSubmit={rateFormik.handleSubmit}>
              <div className="form-group row row-cols-3">

                {/* <div className='d-flex gap-3'>
                  <label htmlFor='minDriverWalletBalance' className=" col-form-label">Min wallet(Cash ride)</label>
                  <div className="">
                    <input type="text" className="form-control" id='minDriverWalletBalance' placeholder="0" name='minDriverWalletBalance' onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values?.minDriverWalletBalance} />
                    {rateFormik.touched?.minDriverWalletBalance && rateFormik.errors?.minDriverWalletBalance ? (<div className='req-error'>{rateFormik?.errors?.minDriverWalletBalance}</div>) : null}
                  </div>
                </div> */}

                <div className='d-flex gap-3'>
                  <label htmlFor='minWidhdrawlAmount' className=" col-form-label">Min widhdrawl amount</label>
                  <div className="">
                    <input type="text" className="form-control" id='minWidhdrawlAmount' placeholder="0" name='minWidhdrawlAmount' onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values?.minWidhdrawlAmount} />
                    {rateFormik.touched?.minWidhdrawlAmount && rateFormik.errors?.minWidhdrawlAmount ? (<div className='req-error'>{rateFormik?.errors?.minWidhdrawlAmount}</div>) : null}
                  </div>
                </div>

                
                <div className="d-flex align-items-end"  >
                  <button type="submit" className="btn btn-sm btn-primary" disabled={submitLoader}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DriverRate